export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        onBack() {
            this.$router.go(-1);
        }
    }
}